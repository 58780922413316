import React from "react"
import { PageTitle } from "../../components/copy"
import SubHeading from "../../components/copy/subHeading"
import "./index.scss"

const tenjoAddresses = [
  {
    name: "Marketing Main Office",
    address: "Ruko Tol Boulevard C6, Jln Pahlawan Seribu, BSD City"
  },
  {
    name: "Tenjo City Gallery Office",
    address: "Jln Kampung Baru Kel.Singabangsa, Kec. Tenjo, Bogor, West Java 16370"
  }
]

const bojongAddresses = [
  {
    name: "Marketing Gallery Office 1",
    address: "Kantor Pemasaran STH Jl. Setapak, Tajurhalang, Tajur Halang, Bogor, Jawa Barat 16320"
  },
  {
    name: "Marketing Gallery Office 2",
    address: "Kantor Pemasaran SDK Jl. Sudimampir Kec. Bojong Gede, Bogor, Jawa Barat 16920"
  }
]

const ContactUsScreen = () => {
  const renderAddress = (name, address) => (
    <div
      key={name}
    >
      <h4>{name}</h4>
      <p className="u-marginTop24">{address}</p>
    </div>
  )

  return (
    <div className="contactUs-screen u-paddingHorizontal16">
      <PageTitle
        className="u-marginTop48"
        title="Contact Us"
      />
      <div className="contactUs-content u-marginTop40">
        <p className="contactUs-body u-margin0 u-body1">
          Kami berkomitmen untuk melayani customer kami secara sungguh-sungguh,
          jika anda membutuhkan bantuan atau memiliki pertanyaan,
          jangan sungkan untuk menghubungi kami.
        </p>
        <a className="contactUs-mail u-marginTop24 u-body1" href="mailto:tenjometro@gmail.com" target="__blank">
          <p>
            <strong>E:</strong> admin@tenjometro.com
          </p>
        </a>
        <p className="u-marginTop12 u-body1">
          <strong>WA (Sylvia):</strong> 0898 620 4907
        </p>
        <p className="u-marginTop12 u-body1">
          <strong>WA (Admin Tenjo City):</strong> 0813 1011 1172
        </p>
        <p className="u-marginTop12 u-body1">
          <strong>WA (Sutanto):</strong> 0813 8186 8338
        </p>
        <SubHeading
          className="u-marginTop64"
          text="Location"
        />
        <h3>Area Tenjo</h3>
        {tenjoAddresses.map(address => (
          renderAddress(address.name, address.address)
        ))}

        <h3 className="u-marginTop64">Area Bojong Gede & Tajur Halang </h3>
        {bojongAddresses.map(address => (
          renderAddress(address.name, address.address)
        ))}
      </div>
    </div>
  )
}

export default ContactUsScreen
