import React from "react"
import cls from "classnames"
import PropTypes from "prop-types"
import "./index.scss"

const SubHeading = ({ text, className }) => (
  <h2 className={cls("subHeading", className)}>
    {text}
  </h2>
)

SubHeading.defaultProps = {
  text: ""
}

SubHeading.propTypes = {
  text: PropTypes.string
}

export default SubHeading
