import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ContactUsScreen from "../screens/contactUs"

const ContactUsPage = () => (
  <Layout>
    <SEO title="Contact Us" />
    <ContactUsScreen />
  </Layout>
)

export default ContactUsPage
